import React, { useState, useCallback } from "react";
import CommonFormLabel from "../../components/formLabel";
import Input from "../../components/input";
import ValidationFeedback from "../../components/validationFeedback";
import CommonButton from "../../components/commonButton";
import FileInput from "../../components/fileInput";
import { useDispatch } from "react-redux";
import {
  Modal,
  Toast,
  Form,
  Col,
  Row
} from "react-bootstrap";
import { Formik } from "formik";
import { addBlog, getBlogs } from "../../modules/Blog/blogSlice";
import { blogSchema } from "../../schema/validationSchemas";
import { Cancel01Icon } from "../../assets/icons";
import Editor from "../../components/commonEditor";

export default function Add({
  show,
  handleClose,
  currentPage,
  searchTerm,
  limit,
  id,
}) {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const token = localStorage.getItem('auth-token');

  const initialEventState = {
    blog_title: "",
    author: "",
    blog_image: "",
    blog_description: "",
  };

  const handleModalClose = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
      setIsSubmitting(true);
      await dispatch(addBlog(values)).then((res) => {
        if(res.payload && res.payload.success) {
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("success");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
          handleClose();
          const payload = {
            page: currentPage,
            search: searchTerm,
            limit: limit,
            token
          };
          dispatch(getBlogs({ payload }));
        } else {
          setErrors(res.payload.data);
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("danger");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
        }
      }).catch((error) => {
        console.error("Error:", error);
        setSnackbarMessage("Failed to add blog!");
        setSnackbarVariant("danger");
        setShowSnackbar(true);
        setSubmitting(false);
        setIsSubmitting(false);
      })
  };

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>

      <Modal
        show={show}
        size="lg"
        centered
        dialogClassName="common-popup-dialog"
        contentClassName="common-popup-content"
        backdropClassName="common-popup-backdrop"
        onHide={handleModalClose}
      >
        
        <Modal.Body className="common-popup-body">
          <div className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center  common-popup-close-btn" onClick={handleModalClose}>
            <Cancel01Icon width={16} height={16} color="#BCBBC1" strokeWidth="2.5" />
          </div>
          <div className="mb-4 text-center common-popup-heading">
            <h4 className="text-capitalize mb-2 fw-medium">New Blog</h4>
            <p>Add new Blog</p>
          </div>
          <Formik
            initialValues={initialEventState}
            validationSchema={blogSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, handleChange, handleSubmit, values, setFieldValue }) => (
              <Form onSubmit={handleSubmit} encType="multipart/form-data">
                <Row>
                  <Col md={6} className="mb-4">
                    <CommonFormLabel htmlFor="blog_title" title="Blog Title" />
                    <Input
                      id="blog_title"
                      name="blog_title"
                      type="text"
                      placeholder="Enter Blog Title"
                      onChange={handleChange}
                      className={`shadow-none ${errors?.blog_title && touched.blog_title ? 'error' : 'correct'}`}
                    />
                    {touched.blog_title && errors?.blog_title && (
                      <ValidationFeedback title={errors?.blog_title} />
                    )}
                  </Col>

                  <Col md={6} className="mb-4">
                    <CommonFormLabel htmlFor="author" title="Author" />
                    <Input
                      id="author"
                      name="author"
                      type="text"
                      placeholder="Enter Author Name"
                      onChange={handleChange}
                      className={`shadow-none ${errors?.author && touched.author ? 'error' : 'correct'}`}
                    />
                    {touched.author && errors?.author && (
                      <ValidationFeedback title={errors?.author} />
                    )}
                  </Col>
                </Row>

                <div className="mb-4">
                  <CommonFormLabel htmlFor="description" title="Description" />
                  <Editor
                    value={values.blog_description}
                    onChange={(content) => setFieldValue("blog_description", content)}
                  />
                  {touched.blog_description && errors?.blog_description && (
                    <ValidationFeedback title={errors?.blog_description} />
                  )}
                </div>

                <div className="mb-4">
                  <CommonFormLabel htmlFor="blog_image" title="Upload Image" />
                  <FileInput
                    name="blog_image"
                    onChange={(file) => setFieldValue("blog_image", file)}
                    error={errors?.blog_image}
                    touched={touched.blog_image}
                  />
                  {touched.blog_image && errors?.blog_image && (
                    <ValidationFeedback title={errors?.blog_image} />
                  )}
                </div>

                <div className="d-flex align-items-center justify-content-center gap-3 common-popup-btn">
                  <CommonButton title={isSubmitting ? "Adding..." : "Add"} type="submit" disabled={isSubmitting} className="btn-primary primary-shadow" />
                  <CommonButton title="Cancel" onClick={handleModalClose} className="btn-muted" />
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}
