import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCategories, createCategory, fetchCategory, modifyCategory, deleteCategoryApi, getAllCategoriesApi, getChildCategoriesApi, getCategoryAttributesApi, changeStatus } from '../../api/categoryApi';

export const getCategories = createAsyncThunk('category/getCategories', async ({payload}, { rejectWithValue }) => {
  try {
    const response = await fetchCategories(payload);
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const addCategory = createAsyncThunk('category/addCategory', async (payload, { rejectWithValue }) => {
  try {
    const response = await createCategory(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getCategoryDetails = createAsyncThunk('category/getCategoryDetails', async (categoryId, { rejectWithValue }) => {
  try {
    const response = await fetchCategory(categoryId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const updateCategory = createAsyncThunk('category/updateCategory', async (payload, { rejectWithValue }) => {
  try {
    const response = await modifyCategory(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deleteCategory = createAsyncThunk('category/deleteCategory', async (categoryId, { rejectWithValue }) => {
  try {
    const response = await deleteCategoryApi(categoryId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getAllCategories = createAsyncThunk('category/getAllCategories', async (payload) => {
  try {
    const response = await getAllCategoriesApi(payload);
    return response.data;
  } catch (error) {
    return "error";
  }
});

export const getChildCategories = createAsyncThunk('category/getChildCategories', async (payload) => {
  try {
    const response = await getChildCategoriesApi(payload);
    return response.data;
  } catch (error) {
    return "error";
  }
});

export const getCategoryAttributes = createAsyncThunk('category/getCategoryAttributes', async (payload) => {
  try {
    const response = await getCategoryAttributesApi(payload);
    return response.data;
  } catch (error) {
    return "error";
  }
});

export const changeActiveStatus = createAsyncThunk('category/changeDefaultStatus', async (categoryId, { rejectWithValue }) => {
  try {
    const response = await changeStatus(categoryId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const categorySlice = createSlice({
  name: 'category',
  initialState: {
    categories: [],
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categories = action.payload;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
});

export default categorySlice.reducer;
