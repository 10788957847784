import { apiHelper } from '../utils/apiHelper';

const API_URL = `${process.env.REACT_APP_API_URL}/api/org/team`;

const headers = {
  "Content-Type": "multipart/form-data",
  "Accept": "application/json"
};

export const fetchTeams = (payload) => apiHelper(API_URL, 'GET', payload);
export const createTeam = (payload) => apiHelper(API_URL, 'POST', payload, headers);
export const fetchTeam = (teamId) => apiHelper(`${API_URL}/${teamId}`, 'GET');
export const modifyTeam = (payload) => apiHelper(`${API_URL}/${payload.get('id')}`, 'POST', payload, headers);
export const deleteTeamApi = (teamId) => apiHelper(`${API_URL}/${teamId}`, 'DELETE');
export const changeStatus = (teamId) => apiHelper(`${API_URL}/change-status/${teamId}`, 'POST');

