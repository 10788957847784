import React, { useState } from "react";
import CommonFormLabel from "../../components/formLabel";
import Input from "../../components/input";
import { useNavigate } from "react-router-dom";
import FileInput from "../../components/fileInput";
import ValidationFeedback from "../../components/validationFeedback";
import CommonButton from "../../components/commonButton";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { Toast, Form, Col, Row, Card } from "react-bootstrap";
import { blogSchema } from "../../schema/validationSchemas";
import { addBlog } from "../../modules/Blog/blogSlice";
import Editor from "../../components/commonEditor";

export default function BlogAddNew() {
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [imagePreview, setImagePreview] = useState(null);
  const dispatch = useDispatch();

  const initialEventState = {
    blog_title: "",
    author: "",
    blog_image: "",
    blog_description: "",
  };

  const handleModalClose = () => {
    navigate("/blogs");
  };

  const handleImageChange = (file, setFieldValue) => {
    if (file) {
      setImagePreview(URL.createObjectURL(file)); // Generate preview URL
      setFieldValue("blog_image", file); // Set the file in Formik
    }
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setIsSubmitting(true);
    await dispatch(addBlog(values))
      .then((res) => {
        if (res.payload && res.payload.success) {
          localStorage.setItem("blog_notify_message", res.payload.message);
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("success");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
          navigate("/blogs");
        } else {
          setErrors(res.payload.data);
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("danger");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setSnackbarMessage("Failed to add blog!");
        setSnackbarVariant("danger");
        setShowSnackbar(true);
        setSubmitting(false);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>
      <Formik
        initialValues={initialEventState}
        validationSchema={blogSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          handleSubmit,
          values,
          setFieldValue,
        }) => (
          <Form
            className="bg-white p-4 rounded-2 shadow-sm "
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
              <Row>
                <Col md={6} className="mb-4">
                  <CommonFormLabel htmlFor="blog_title" title="Blog Title" />
                  <Input
                    id="blog_title"
                    name="blog_title"
                    type="text"
                    placeholder="Enter Blog Title"
                    onChange={handleChange}
                    className={`shadow-none ${
                      errors?.blog_title && touched.blog_title
                        ? "error"
                        : "correct"
                    }`}
                  />
                  {touched.blog_title && errors?.blog_title && (
                    <ValidationFeedback title={errors?.blog_title} />
                  )}
                </Col>

                <Col md={6} className="mb-4">
                  <CommonFormLabel htmlFor="author" title="Author" />
                  <Input
                    id="author"
                    name="author"
                    type="text"
                    placeholder="Enter Author Name"
                    onChange={handleChange}
                    className={`shadow-none ${
                      errors?.author && touched.author ? "error" : "correct"
                    }`}
                  />
                  {touched.author && errors?.author && (
                    <ValidationFeedback title={errors?.author} />
                  )}
                </Col>
              </Row>

              <div className="mb-4">
                <CommonFormLabel htmlFor="description" title="Description" />
                <Editor
                  value={values.blog_description}
                  onChange={(content) =>
                    setFieldValue("blog_description", content)
                  }
                  height="250px"
                />
                {touched.blog_description && errors?.blog_description && (
                  <ValidationFeedback title={errors?.blog_description} />
                )}
              </div>
            </Card>

            <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
              <div className="mb-4">
                <CommonFormLabel htmlFor="blog_image" title="Upload Image" />
                <FileInput
                  name="blog_image"
                  onChange={(file) => handleImageChange(file, setFieldValue)}
                  error={errors?.blog_image}
                  touched={touched.blog_image}
                  inputType="image"
                />
                {touched.blog_image && errors?.blog_image && (
                  <ValidationFeedback title={errors?.blog_image} />
                )}
                {imagePreview && (
                  <div className="mt-3">
                    <img
                      src={imagePreview}
                      alt="Blog Preview"
                      style={{
                        width: "200px",
                        height: "auto",
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                )}
              </div>
            </Card>

            <div className="d-flex align-items-center justify-content-end gap-3 common-popup-btn">
              <CommonButton
                title={isSubmitting ? "Adding..." : "Add"}
                type="submit"
                disabled={isSubmitting}
                className="btn-primary primary-shadow"
              />
              <CommonButton
                title="Cancel"
                onClick={handleModalClose}
                className="btn-muted"
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
