import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchSettings, modifySettings } from '../../api/settingApi';

export const getSettings = createAsyncThunk('setting/getSettings', async () => {
    try {
        const response = await fetchSettings();
        return response.data.data;
    } catch (error) {
        console.error("Error fetching settings:", error);
    }
});

export const updateSettings = createAsyncThunk('setting/updateSettings', async (payload, { rejectWithValue }) => {
    try {
      const response = await modifySettings(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  });
  

const settingSlice = createSlice({
  name: 'setting',
  initialState: {
    settings: {},
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSettings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.settings = action.payload;
      })
      .addCase(getSettings.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
});

export default settingSlice.reducer;
