import { apiHelper } from '../utils/apiHelper';

const API_URL = `${process.env.REACT_APP_API_URL}/api/org/gallery`;

const headers = {
  "Content-Type": "multipart/form-data",
  "Accept": "application/json"
};

export const fetchGalleries = (payload) => apiHelper(API_URL, 'GET', payload);
export const createGallery = (payload) => apiHelper(API_URL, 'POST', payload, headers);
export const fetchGallery = (galleryId) => apiHelper(`${API_URL}/${galleryId}`, 'GET');
export const modifyGallery = (payload) => apiHelper(`${API_URL}/${payload.get('id')}`, 'POST', payload, headers);
export const deleteGalleryApi = (galleryId) => apiHelper(`${API_URL}/${galleryId}`, 'DELETE');
export const changeStatus = (galleryId) => apiHelper(`${API_URL}/change-status/${galleryId}`, 'POST');

