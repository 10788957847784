import React, { useEffect, useRef, useState } from "react";
import SidebarItem from "./sidebar-item";
import { Link, useLocation } from "react-router-dom";
import { Image } from "react-bootstrap";
import { SidebarContent } from "./sidebar-content";
import logoBlack from "../../assets/images/logo/logo-dark.png";
import "./sidebar.css";

export default function Sidebar({ className }) {
  const [scrolled, setScrolled] = useState(false);
  const sidebarRef = useRef(null);

  const location = useLocation();

  const handleSidebarScroll = () => {
    const scrollPosition = sidebarRef.current.scrollTop;

    if (scrollPosition > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    const sidebar = sidebarRef.current;
    if (sidebar) {
      sidebar.addEventListener("scroll", handleSidebarScroll);
    }
    return () => {
      if (sidebar) {
        sidebar.removeEventListener("scroll", handleSidebarScroll);
      }
    };
  }, []);

  return (
    <>
      <aside className={`bg-white ${className} sidebar`}>
        <div className="h-100 position-relative d-flex flex-column">
          <div className="flex-shrik-1 position-relative mt-3 mb-2">
            <div
              className={`d-flex align-items-center justify-content-center position-sticky top-0 start-0 end-0 site-logo ${
                scrolled ? "effect-logo" : null
              }`}
            >
              <Link to="/dashboard">
                <h1>
                  <Image src={logoBlack} />
                </h1>
              </Link>
            </div>
          </div>
          <div className="py-1 flex-grow-1 overflow-y-auto" ref={sidebarRef}>
            <ul className="mb-0 list-unstyled">
              {SidebarContent.map((item, i) => {
                return (
                  <SidebarItem
                    key={i}
                    to={item.link}
                    icon={item.icon}
                    title={item.title}
                    className={
                      location.pathname === item.link ? "active" : null
                    }
                  />
                );
              })}
            </ul>
          </div>
        </div>
      </aside>
    </>
  );
}
