// import axios from 'axios';

// export const apiHelper = (url, method = 'GET', data = null, token = null) => {
//   const headers = token ? { Authorization: `Bearer ${token}` } : {};
//   return axios({
//     method,
//     url,
//     data,
//     headers,
//   });
// };


// import axios from 'axios';

// export const apiHelper = async (url, method = 'GET', data = null) => {
//   try {

//     console.log("data", data);
    
//     const token = localStorage.getItem('auth-token');
//     const headers = token ? { Authorization: `Bearer ${token}` } : {};
//     const response = await axios({
//       method,
//       url,
//       data,
//       headers,
//       withCredentials: true,
//     });
//     return response;  // Successful API call
//   } catch (error) {
//     // Centralized error handling
//     if (error.response) {
//       // Server responded with a status other than 200 range
//       throw new Error(error.response.data.message || 'Something went wrong!');
//     } else if (error.request) {
//       // Request was made but no response was received
//       throw new Error('Network error, please try again later.');
//     } else {
//       // Something else happened
//       throw new Error('An unexpected error occurred.');
//     }
//   }
// };


import axios from 'axios';

export const apiHelper = async (url, method, data = null, customHeaders  = {}) => {
  try {
    const token = localStorage.getItem('auth-token');
    // const headers = token ? { Authorization: `Bearer ${token}` } : {};
    let defaultHeaders = token ? { Authorization: `Bearer ${token}` } : {};

    const headers = {
      ...defaultHeaders,
      ...customHeaders,
    };
    
    const config = {
      method,
      url,
      headers,
      withCredentials: true,
    };

    // if (method === 'GET' && data) {
    //   config.params = data;
    // } else if (data) {
    //   config.data = data;
    // }

    if (data instanceof FormData) {
      config.data = data;
    } else if (method === 'GET' && data) {
      config.params = data;
    } else if (data) {
      config.data = data;
    }

    const response = await axios(config);
    return response;
  } catch (error) {
    // Centralized error handling
    if (error.response) {
      // Server responded with a status other than 200 range
      throw new Error(error.response.data.message || 'Something went wrong!');
    } else if (error.request) {
      // Request was made but no response was received
      throw new Error('Network error, please try again later.');
    } else {
      // Something else happened
      throw new Error('An unexpected error occurred.');
    }
  }
};
