import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchGalleries, createGallery, fetchGallery, modifyGallery, deleteGalleryApi, changeStatus } from '../../api/galleryApi';

export const getGalleries = createAsyncThunk('gallery/getGalleries', async ({payload}, { rejectWithValue }) => {
  try {
    const response = await fetchGalleries(payload);
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const addGallery = createAsyncThunk('gallery/addGallery', async (payload, { rejectWithValue }) => {
  try {
    const response = await createGallery(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getGalleryDetails = createAsyncThunk('gallery/getGalleryDetails', async (galleryId, { rejectWithValue }) => {
  try {
    const response = await fetchGallery(galleryId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const updateGallery = createAsyncThunk('gallery/updateGallery', async (payload, { rejectWithValue }) => {
  try {
    const response = await modifyGallery(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deleteGallery = createAsyncThunk('gallery/deleteGallery', async (galleryId, { rejectWithValue }) => {
  try {
    const response = await deleteGalleryApi(galleryId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const changeActiveStatus = createAsyncThunk('gallery/changeDefaultStatus', async (galleryId, { rejectWithValue }) => {
  try {
    const response = await changeStatus(galleryId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const gallerySlice = createSlice({
  name: 'gallery',
  initialState: {
    galleries: [],
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGalleries.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getGalleries.fulfilled, (state, action) => {
        state.isLoading = false;
        state.galleries = action.payload;
      })
      .addCase(getGalleries.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
});

export default gallerySlice.reducer;
