import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchInquiries, deleteInquiryApi, changeStatus } from '../../api/inquiryApi';

export const getInquiries = createAsyncThunk('inquiry/getInquiries', async ({payload}, { rejectWithValue }) => {
  try {
    const response = await fetchInquiries(payload);
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deleteInquiry = createAsyncThunk('inquiry/deleteInquiry', async (inquiryId, { rejectWithValue }) => {
  try {
    const response = await deleteInquiryApi(inquiryId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const changeActiveStatus = createAsyncThunk('inquiry/changeDefaultStatus', async (inquiryId, { rejectWithValue }) => {
  try {
    const response = await changeStatus(inquiryId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const inquirySlice = createSlice({
  name: 'inquiry',
  initialState: {
    inquiries: [],
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInquiries.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getInquiries.fulfilled, (state, action) => {
        state.isLoading = false;
        state.inquiries = action.payload;
      })
      .addCase(getInquiries.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
});

export default inquirySlice.reducer;
