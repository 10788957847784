import React from 'react'
import { Badge } from 'react-bootstrap';
import "./statusChip.css";

export default function StatusChip({ bg, title, className, onClick }) {
    return (
        <Badge 
            bg={bg} 
            className={` fw-medium rounded-1 custom-status-chip ${className}`}
            onClick={onClick}
            style={{ cursor: onClick ? 'pointer' : 'default' }}
        >
            {title}
        </Badge>
    )
}
