import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchTeams, createTeam, fetchTeam, modifyTeam, deleteTeamApi, changeStatus } from '../../api//teamApi';

export const getTeams = createAsyncThunk('team/getTeams', async ({payload}, { rejectWithValue }) => {
  try {
    const response = await fetchTeams(payload);
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const addTeam = createAsyncThunk('team/addTeam', async (payload, { rejectWithValue }) => {
  try {
    const response = await createTeam(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getTeamDetails = createAsyncThunk('team/getTeamDetails', async (teamId, { rejectWithValue }) => {
  try {
    const response = await fetchTeam(teamId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const updateTeam = createAsyncThunk('team/updateTeam', async (payload, { rejectWithValue }) => {
  try {
    const response = await modifyTeam(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deleteTeam = createAsyncThunk('team/deleteTeam', async (teamId, { rejectWithValue }) => {
  try {
    const response = await deleteTeamApi(teamId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const changeActiveStatus = createAsyncThunk('team/changeActiveStatus', async (teamId, { rejectWithValue }) => {
  try {
    const response = await changeStatus(teamId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const teamSlice = createSlice({
  name: 'team',
  initialState: {
    teams: [],
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeams.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getTeams.fulfilled, (state, action) => {
        state.isLoading = false;
        state.teams = action.payload;
      })
      .addCase(getTeams.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
});

export default teamSlice.reducer;
