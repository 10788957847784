import React from 'react';
import Select from 'react-select';
import ValidationFeedback from '../validationFeedback'; // Assuming this is your custom feedback component

const SimpleDropdown = ({
  label,
  name,
  value,
  onChange,
  options,
  placeholder = 'Select an option',
  error,
  touched,
}) => {
  return (
    <div className="mb-4">
      {label && <label htmlFor={name} className="mb-1 common-form-label">{label}</label>}
      <Select
        name={name}
        value={options.find((option) => option?.value === value) || null}
        options={options}
        // onChange={(selectedOption) => onChange(selectedOption)}
        onChange={(selectedOption) => onChange(selectedOption ? selectedOption : { value: '', label: '' })}
        placeholder={placeholder}
        classNamePrefix="custom-dropdown"
        isClearable={true}
      />
      {touched && error ? <ValidationFeedback title={error} /> : null}
    </div>
  );
};

export default SimpleDropdown;
