import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDashboardCounts } from '../../api/dashboardApi';

export const getDashboardCounts = createAsyncThunk('dashboard/getDashboardCounts', async (_, { rejectWithValue }) => {
  try {
    const response = await fetchDashboardCounts();
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    dashboardCounts: [],
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardCounts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDashboardCounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dashboardCounts = action.payload;
      })
      .addCase(getDashboardCounts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
});

export default dashboardSlice.reducer;
