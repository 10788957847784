import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchMarketAreas, createMarketArea, fetchMarketArea, modifyMarketArea, deleteMarketAreaApi, changeStatus } from '../../api/marketAreaApi';

export const getMarketAreas = createAsyncThunk('marketArea/getMarketAreas', async ({payload}, { rejectWithValue }) => {
  try {
    const response = await fetchMarketAreas(payload);
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const addMarketArea = createAsyncThunk('marketArea/addMarketArea', async (payload, { rejectWithValue }) => {
  try {
    const response = await createMarketArea(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getMarketAreaDetails = createAsyncThunk('marketArea/getMarketAreaDetails', async (marketAreaId, { rejectWithValue }) => {
  try {
    const response = await fetchMarketArea(marketAreaId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const updateMarketArea = createAsyncThunk('marketArea/updateMarketArea', async (payload, { rejectWithValue }) => {
  try {
    const response = await modifyMarketArea(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deleteMarketArea = createAsyncThunk('marketArea/deleteMarketArea', async (marketAreaId, { rejectWithValue }) => {
  try {
    const response = await deleteMarketAreaApi(marketAreaId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const changeDefaultStatus = createAsyncThunk('marketArea/changeDefaultStatus', async (marketAreaId, { rejectWithValue }) => {
  try {
    const response = await changeStatus(marketAreaId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const marketAreaSlice = createSlice({
  name: 'marketArea',
  initialState: {
    marketAreas: [],
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMarketAreas.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getMarketAreas.fulfilled, (state, action) => {
        state.isLoading = false;
        state.marketAreas = action.payload;
      })
      .addCase(getMarketAreas.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
});

export default marketAreaSlice.reducer;
