import React, { useState, useCallback, useEffect } from "react";
import CommonFormLabel from "../../components/formLabel";
import Input from "../../components/input";
import ValidationFeedback from "../../components/validationFeedback";
import CommonButton from "../../components/commonButton";
import FileInput from "../../components/fileInput";
import { useDispatch } from "react-redux";
import { Modal, Toast, Form, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import { updateBlog, getBlogDetails, getBlogs } from "../../modules/Blog/blogSlice";
import { blogUpdateSchema } from "../../schema/validationSchemas";
import { Cancel01Icon } from "../../assets/icons";
import Editor from "../../components/commonEditor";

export default function Edit({
  show,
  handleClose,
  blogId,
  currentPage,
  searchTerm,
  limit,
}) {
  const [blog, setBlog] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const token = localStorage.getItem("auth-token");

  const fetchBlog = useCallback(async () => {
    try {
      const result = await dispatch(getBlogDetails(blogId));
      setBlog(result.payload.data);
    } catch (error) {
      console.error("Error fetching blog:", error);
    }
  }, [dispatch, blogId]);

  useEffect(() => {
    setBlog(null);
    if (blogId && show) {
      fetchBlog();
    }
  }, [blogId, show, fetchBlog]);

  const handleModalClose = useCallback(() => {
    setBlog(null);
    handleClose();
  }, [handleClose]);

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setIsSubmitting(true);
    const updatedValues = { ...values, id: blogId };

    const formData = new FormData();
    formData.append('id', updatedValues.id);
    formData.append('_method', 'PUT');
    formData.append('blog_title', updatedValues.blog_title);
    formData.append('blog_description', updatedValues.blog_description);
    formData.append('author', updatedValues.author);
    formData.append('blog_image', updatedValues.blog_image);

    await dispatch(updateBlog(formData))
      .then((res) => {
        if (res.payload && res.payload.success) {
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("success");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
          setBlog(null);
          handleClose();
          const payload = {
            page: currentPage,
            search: searchTerm,
            limit: limit,
            token,
          };
          dispatch(getBlogs({ payload }));
        } else {
          setErrors(res.payload.data);
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("danger");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        setSnackbarMessage("Failed to update blog!");
        setSnackbarVariant("danger");
        setShowSnackbar(true);
        setSubmitting(false);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>

      <Modal
        show={show}
        size="lg"
        centered
        dialogClassName="common-popup-dialog"
        contentClassName="common-popup-content"
        backdropClassName="common-popup-backdrop"
        onHide={handleModalClose}
      >
        <Modal.Body className="common-popup-body">
          <div
            className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center common-popup-close-btn"
            onClick={handleModalClose}
          >
            <Cancel01Icon width={16} height={16} color="#BCBBC1" strokeWidth="2.5" />
          </div>
          <div className="mb-4 text-center common-popup-heading">
            <h4 className="text-capitalize mb-2 fw-medium">Edit Blog</h4>
            <p>Edit existing blog</p>
          </div>

          {blog && (
            <Formik
              initialValues={{
                blog_title: blog.blog_title || "",
                author: blog.author || "",
                blog_image: "", 
                blog_description: blog.blog_description || "",
              }}
              validationSchema={blogUpdateSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, handleChange, handleSubmit, values, setFieldValue }) => (
                <Form onSubmit={handleSubmit} encType="multipart/form-data">
                  <Row>
                    <Col md={6} className="mb-4">
                      <CommonFormLabel htmlFor="blog_title" title="Title" />
                      <Input
                        id="blog_title"
                        name="blog_title"
                        type="text"
                        placeholder="Enter Blog Title"
                        onChange={handleChange}
                        value={values.blog_title}
                        className={`shadow-none ${errors?.blog_title && touched.blog_title ? 'error' : 'correct'}`}
                      />
                      {touched.blog_title && errors?.blog_title && (
                        <ValidationFeedback title={errors?.blog_title} />
                      )}
                    </Col>

                    <Col md={6} className="mb-4">
                      <CommonFormLabel htmlFor="author" title="Author" />
                      <Input
                        id="author"
                        name="author"
                        type="text"
                        placeholder="Enter Author Name"
                        onChange={handleChange}
                        value={values.author}
                        className={`shadow-none ${errors?.author && touched.author ? 'error' : 'correct'}`}
                      />
                      {touched.author && errors?.author && (
                        <ValidationFeedback title={errors?.author} />
                      )}
                    </Col>
                  </Row>

                  <div className="mb-4">
                    <CommonFormLabel htmlFor="blog_description" title="Description" />
                    <Editor
                      value={values.blog_description}
                      onChange={(content) => setFieldValue("blog_description", content)}
                    />
                    {touched.blog_description && errors?.blog_description && (
                      <ValidationFeedback title={errors?.blog_description} />
                    )}
                  </div>

                  {/* blog Image */}
                  <div className="mb-4">
                    <CommonFormLabel htmlFor="blog_image" title="Upload New Image" />
                    <FileInput
                      name="blog_image"
                      onChange={(file) => setFieldValue("blog_image", file)}
                      error={errors?.blog_image}
                      touched={touched.blog_image}
                    />
                    {touched.blog_image && errors?.blog_image && (
                      <ValidationFeedback title={errors?.blog_image} />
                    )}
                  </div>

                  {/* <hr className="my-5" /> */}
                  <Row>
                    <Col md={6} className="">
                  <CommonFormLabel htmlFor="blog_image" title="Uploaded Image" />
                    <div className="mt-2">
                        <img src={blog.url + "/" + blog.blog_image} alt="Blog" className="img-fluid" height={"100px"} width={"100px"} />
                      </div>
                    </Col>
                  </Row>
                  <hr className="my-5" />


                  <div className="d-flex align-items-center justify-content-center gap-3 common-popup-btn">
                    <CommonButton title={isSubmitting ? "Updating..." : "Update"} type="submit" disabled={isSubmitting} className="btn-primary primary-shadow" />
                    <CommonButton title="Cancel" onClick={handleModalClose} className="btn-muted" />
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
