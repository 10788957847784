import { apiHelper } from '../utils/apiHelper';

const API_URL = `${process.env.REACT_APP_API_URL}/api/org/dashboard-counts`;

// const headers = {
//   "Content-Type": "multipart/form-data",
//   "Accept": "application/json"
// };

export const fetchDashboardCounts = () => apiHelper(API_URL, 'GET');

