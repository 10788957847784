import { apiHelper } from '../utils/apiHelper';

const API_URL = `${process.env.REACT_APP_API_URL}/api/org/market-area`;

const headers = {
  "Content-Type": "multipart/form-data",
  "Accept": "application/json"
};

export const fetchMarketAreas = (payload) => apiHelper(API_URL, 'GET', payload);
export const createMarketArea = (payload) => apiHelper(API_URL, 'POST', payload, headers);
export const fetchMarketArea = (marketAreaId) => apiHelper(`${API_URL}/${marketAreaId}`, 'GET');
export const modifyMarketArea = (payload) => apiHelper(`${API_URL}/${payload.get('id')}`, 'POST', payload, headers);
export const deleteMarketAreaApi = (marketAreaId) => apiHelper(`${API_URL}/${marketAreaId}`, 'DELETE');
export const changeStatus = (marketAreaId) => apiHelper(`${API_URL}/change-status/${marketAreaId}`, 'POST');