import React, { useEffect, useState, useCallback } from "react";
import { Modal, Toast, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getGalleryDetails } from "../../modules/Gallery/gallerySlice";
import { Cancel01Icon } from "../../assets/icons";

export default function GalleryView({
  show,
  handleClose,
  galleryId,
}) {
  const [gallery, setGallery] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const dispatch = useDispatch();

  const fetchGallery = useCallback(async () => {
    try {
      const result = await dispatch(getGalleryDetails(galleryId));
      setGallery(result.payload.data);
    } catch (error) {
      setSnackbarMessage("Failed to load gallery details!");
      setSnackbarVariant("danger");
      setShowSnackbar(true);
    }
  }, [dispatch, galleryId]);

  useEffect(() => {
    if (galleryId && show) {
      fetchGallery();
    }
  }, [galleryId, show, fetchGallery]);

  const handleModalClose = useCallback(() => {
    setGallery(null);
    handleClose();
  }, [handleClose]);

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>

      <Modal
        show={show}
        size="lg"
        centered
        dialogClassName="common-popup-dialog"
        contentClassName="common-popup-content"
        backdropClassName="common-popup-backdrop"
        onHide={handleModalClose}
      >
        <Modal.Body className="common-popup-body">
          <div
            className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center common-popup-close-btn"
            onClick={handleModalClose}
          >
            <Cancel01Icon width={16} height={16} color="#BCBBC1" strokeWidth="2.5" />
          </div>
          <div className="mb-4 text-center common-popup-heading">
            <h4 className="text-capitalize mb-2 fw-medium">View Gallery</h4>
            <p>gallery details</p>
          </div>

          {gallery ? (
            <div>
              <Row>
                <Col md={12} className="mb-4">
                  <strong>Gallery Title:</strong>
                  <p>{gallery.gallery_title}</p>
                </Col>
              </Row>

              <hr></hr>

              {/* Display Image */}
              {gallery.gallery_image && (
                <div className="mb-4">
                  <strong>Gallery Image:</strong>
                  <div className="mt-2">
                    <img src={gallery.url + "/" + gallery.gallery_image} alt="Gallery" className="img-fluid" height={"150px"} width={"150px"} />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <p>Loading gallery details...</p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
