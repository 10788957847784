import React, { useState, useCallback, useEffect } from "react";
import CommonFormLabel from "../../components/formLabel";
import Input from "../../components/input";
import TextArea from "../../components/commonTextArea";
import ValidationFeedback from "../../components/validationFeedback";
import CommonButton from "../../components/commonButton";
import SimpleDropdown from "../../components/simpleDropdown";
import { useDispatch } from "react-redux";
import {
  Modal,
  Toast,
  Form,
  Col,
  Row
} from "react-bootstrap";
import { Formik } from "formik";
import { addProduct, getProducts } from "../../modules/Product/productSlice";
import { getChildCategories, getAllCategories, getCategoryAttributes } from "../../modules/Category/categorySlice";
import { storeProductSchema } from "../../schema/validationSchemas";
import { Cancel01Icon } from "../../assets/icons";
import Editor from "../../components/commonEditor";

export default function Add({
  show,
  handleClose,
  currentPage,
  searchTerm,
  limit,
  id,
}) {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryAttributes, setCategoryAttributes] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [productImages, setProductImages] = useState([]);

  const dispatch = useDispatch();

  const token = localStorage.getItem('auth-token');

  const initialEventState = {
    category_id: "",
    sub_category_id: "",
    product_name: "",
    product_code: "",
    description: "",
    price: "",
    selling_price: "",
    quantity: "",
    min_order_quantity: "",
    meta_title: "",
    meta_description: "",
    meta_keyword: "",
    
  };

  const handleModalClose = useCallback(() => {
    setSelectedCategoryId(null);
    setSelectedSubCategoryId(null);
    setCategoryOptions([]);
    setCategoryAttributes([]);
    setSubCategoryOptions([]);
    setSelectedAttributes({});
    setProductImages([]);
    handleClose();
  }, [handleClose]);

  const fetchCategories = useCallback(async () => {
    try {
      await dispatch(getAllCategories()).then((res) => {
        if (res.payload.success) {
          if (res.payload.data.length > 0) {
            const formattedData = res.payload.data.map((item) => ({
              value: item.id, 
              label: item.category_name, 
            }));
            setCategoryOptions(formattedData);
          }
        } else {
          setCategoryOptions([]);
        }
      });
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }, [dispatch]);

  const fetchSubCategories = useCallback(async (categoryId) => {
    try {
      const payload = {
        'category_id' : categoryId
      }
      await dispatch(getChildCategories(payload)).then((res) => {
        if (res.payload.success) {
          const formattedData = res.payload.data.map((item) => ({
            value: item.id,
            label: item.category_name,
          }));
          setSubCategoryOptions(formattedData);
        } else {
          setSubCategoryOptions([]);
        }
      });
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      setSubCategoryOptions([]);
    }
  }, [dispatch]);

  const fetchCategoryAttributes = useCallback(async (subCategoryId) => {
    try {
      const payload = {
        'category_id' : subCategoryId
      }
      await dispatch(getCategoryAttributes(payload)).then((res) => {
        if (res.payload.success) {
          // const formattedData = res.payload.data.map((item) => ({
          //   value: item.id,
          //   label: item.category_name,
          // }));
          setCategoryAttributes(res.payload.data);
        } else {
          setCategoryAttributes([]);
        }
      });
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      setCategoryAttributes([]);
    }
  }, [dispatch]);

  const handleAttributeChange = (groupId, valueId) => {
    setSelectedAttributes((prevState) => {
      const currentGroupValues = prevState[groupId] || [];
  
      // If the value is already selected, remove it; otherwise, add it.
      const updatedGroupValues = currentGroupValues.includes(valueId)
        ? currentGroupValues.filter((id) => id !== valueId)
        : [...currentGroupValues, valueId];
  
      return {
        ...prevState,
        [groupId]: updatedGroupValues,
      };
    });
  };
  
  // const handleImageChange = (event) => {
  //   const files = Array.from(event.target.files);
  //   setProductImages(files);
  // };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to array
    const newFiles = [];

    files.forEach((file) => {
      const exists = productImages.some(
        (img) => img.name === file.name && img.size === file.size
      );
      if (!exists) {
        newFiles.push(file);
      }
    });

    setProductImages((prevImages) => [...prevImages, ...newFiles]);

    // Reset the input field to allow selecting the same file again after removal
    event.target.value = null;
  };

  // const handleImageChange = (event) => {
  //   const files = Array.from(event.target.files); // Convert FileList to array
  //   setProductImages((prevImages) => [...prevImages, ...files]);
  // };

  // Remove an image from the list
  const handleRemoveImage = (index) => {
    setProductImages((prevImages) =>
      prevImages.filter((_, i) => i !== index)
    );
  };

  useEffect(() => {
    if (show) {
      fetchCategories();
    }
  }, [show, fetchCategories]);

  useEffect(() => {
    if (selectedCategoryId) {
      fetchSubCategories(selectedCategoryId);
    } else {
      setSubCategoryOptions([]);
    }
  }, [selectedCategoryId, fetchSubCategories]);

  useEffect(() => {
    if (selectedSubCategoryId) {
      fetchCategoryAttributes(selectedSubCategoryId);
    }
  }, [selectedSubCategoryId, fetchCategoryAttributes]);

  
  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
      setIsSubmitting(true);

      const formData = new FormData();

  // Append form values (text fields)
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      // Append product attributes in a way that Laravel can parse them as arrays
      Object.entries(selectedAttributes).forEach(([attributeGroupId, attributeGroupValueIds]) => {
        attributeGroupValueIds.forEach((valueId, index) => {
          formData.append(`product_attributes[${attributeGroupId}][${index}]`, valueId);
        });
      });

      // Append each image file
      productImages.forEach((image, index) => {
        formData.append(`product_images[${index}]`, image);
      });

      await dispatch(addProduct(formData)).then((res) => {
        if(res.payload && res.payload.success) {
          setProductImages([]);
          setSelectedAttributes({});
          setSelectedCategoryId(null);
          setSelectedSubCategoryId(null);
          setCategoryOptions([]);
          setCategoryAttributes([]);
          setSubCategoryOptions([]);
          setSnackbarMessage(res.payload.message);

          setSnackbarVariant("success");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
          handleClose();
          const payload = {
            page: currentPage,
            search: searchTerm,
            limit: limit,
            token
          };
          dispatch(getProducts({ payload }));
        } else {
          setErrors(res.payload.data);
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("danger");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
        }
      }).catch((error) => {
        console.error("Error:", error);
        setSnackbarMessage("Failed to add product!");
        setSnackbarVariant("danger");
        setShowSnackbar(true);
        setSubmitting(false);
        setIsSubmitting(false);
      })
  };

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>

      <Modal
        show={show}
        size="lg"
        centered
        dialogClassName="common-popup-dialog"
        contentClassName="common-popup-content"
        backdropClassName="common-popup-backdrop"
        onHide={handleModalClose}
      >
        
        <Modal.Body className="common-popup-body">
          <div className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center  common-popup-close-btn" onClick={handleModalClose}>
            <Cancel01Icon width={16} height={16} color="#BCBBC1" strokeWidth="2.5" />
          </div>
          <div className="mb-4 text-center common-popup-heading">
            <h4 className="text-capitalize mb-2 fw-medium">New Product</h4>
            <p>Add new Product</p>
          </div>
          <Formik
            initialValues={initialEventState}
            validationSchema={storeProductSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, handleChange, handleSubmit, values, setFieldValue }) => (
              <Form onSubmit={handleSubmit} encType="multipart/form-data">
                <Row>
                  <Col md={12} className="">
                    <SimpleDropdown
                      label="Category"
                      name="category_id"
                      value={values.category_id}
                      onChange={(selectedOption) => {
                        setFieldValue("category_id", selectedOption.value);
                        setSelectedCategoryId(selectedOption.value);
                      }}
                      options={categoryOptions}
                      placeholder="Select a Category"
                      error={errors?.category_id}
                      touched={touched.category_id}
                    />
                  </Col>
                  <Col md={12} className="">
                    <SimpleDropdown
                      label="Sub Category"
                      name="sub_category_id"
                      value={values.sub_category_id}
                      onChange={(selectedOption) => {
                        setSelectedSubCategoryId(selectedOption.value);
                        setFieldValue("sub_category_id", selectedOption.value)
                      }}
                      options={subCategoryOptions}
                      placeholder="Select a Sub Category"
                      error={errors?.sub_category_id}
                      touched={touched.sub_category_id}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6} className="mb-4">
                    <CommonFormLabel htmlFor="product_name" title="Product Name" />
                    <Input
                      id="product_name"
                      name="product_name"
                      type="text"
                      placeholder="Enter Product Name"
                      onChange={handleChange}
                      className={`shadow-none ${errors?.product_name && touched.product_name ? 'error' : 'correct'}`}
                    />
                    {touched.product_name && errors?.product_name && (
                      <ValidationFeedback title={errors?.product_name} />
                    )}
                  </Col>

                  <Col md={6} className="mb-4">
                    <CommonFormLabel htmlFor="product_code" title="Product Code" />
                    <Input
                      id="product_code"
                      name="product_code"
                      type="text"
                      placeholder="Enter Product Code"
                      onChange={handleChange}
                      className={`shadow-none ${errors?.product_code && touched.product_code ? 'error' : 'correct'}`}
                    />
                    {touched.product_code && errors?.product_code && (
                      <ValidationFeedback title={errors?.product_code} />
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col md={6} className="mb-4">
                    <CommonFormLabel htmlFor="price" title="Price (₹)" />
                    <Input
                      id="price"
                      name="price"
                      type="number"
                      min="1"
                      placeholder="Enter Price"
                      onChange={handleChange}
                      className={`shadow-none ${errors?.price && touched.price ? 'error' : 'correct'}`}
                    />
                    {touched.price && errors?.price && (
                      <ValidationFeedback title={errors?.price} />
                    )}
                  </Col>

                  <Col md={6} className="mb-4">
                    <CommonFormLabel htmlFor="selling_price" title="Selling Price (₹)" />
                    <Input
                      id="selling_price"
                      name="selling_price"
                      type="number"
                      min="1"
                      placeholder="Enter Selling Price"
                      onChange={handleChange}
                      className={`shadow-none ${errors?.selling_price && touched.selling_price ? 'error' : 'correct'}`}
                    />
                    {touched.selling_price && errors?.selling_price && (
                      <ValidationFeedback title={errors?.selling_price} />
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col md={6} className="mb-4">
                    <CommonFormLabel htmlFor="quantity" title="Quantity" />
                    <Input
                      id="quantity"
                      name="quantity"
                      type="number"
                      min="1"
                      placeholder="Enter Quantity"
                      onChange={handleChange}
                      className={`shadow-none ${errors?.quantity && touched.quantity ? 'error' : 'correct'}`}
                    />
                    {touched.quantity && errors?.quantity && (
                      <ValidationFeedback title={errors?.quantity} />
                    )}
                  </Col>

                  <Col md={6} className="mb-4">
                    <CommonFormLabel htmlFor="min_order_quantity" title="min Order Quantity" />
                    <Input
                      id="min_order_quantity"
                      name="min_order_quantity"
                      type="number"
                      min="1"
                      placeholder="Enter min Order Quantity"
                      onChange={handleChange}
                      className={`shadow-none ${errors?.min_order_quantity && touched.min_order_quantity ? 'error' : 'correct'}`}
                    />
                    {touched.min_order_quantity && errors?.min_order_quantity && (
                      <ValidationFeedback title={errors?.min_order_quantity} />
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col md={12} className="mb-4">
                    <CommonFormLabel htmlFor="description" title="Description" />
                    <Editor
                      value={values.description}
                      onChange={(content) => setFieldValue("description", content)}
                    />
                    {touched.description && errors?.description && (
                      <ValidationFeedback title={errors?.description} />
                    )}
                  </Col>
                </Row>

                <hr />

                <div className="mb-4 text-center common-popup-heading">
                  <h4 className="text-capitalize mb-2 fw-medium">Meta Content</h4>
                  <p>Add meta content of product</p>
                </div>

                <Row>
                  <Col md={6} className="mb-4">
                    <CommonFormLabel htmlFor="meta_title" title="Meta Title" />
                    <Input
                      id="meta_title"
                      name="meta_title"
                      type="text"
                      placeholder="Enter Meta Title"
                      onChange={handleChange}
                      className={`shadow-none ${errors?.meta_title && touched.meta_title ? 'error' : 'correct'}`}
                    />
                    {touched.meta_title && errors?.meta_title && (
                      <ValidationFeedback title={errors?.meta_title} />
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col md={12} className="mb-4">
                    <CommonFormLabel htmlFor="meta_description" title="Meta Description" />
                    <TextArea
                      id="meta_description"
                      name="meta_description"
                      placeholder="Enter Meta Description"
                      value={values.meta_description}
                      onChange={handleChange}
                      className={`shadow-none ${errors?.meta_description && touched.meta_description ? 'error' : 'correct'}`}
                      rows={4}
                    />
                    {touched.meta_description && errors?.meta_description && (
                      <ValidationFeedback title={errors?.meta_description} />
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col md={12} className="mb-4">
                    <CommonFormLabel htmlFor="meta_keyword" title="Meta Keywords" />
                    <TextArea
                      id="meta_keyword"
                      name="meta_keyword"
                      value={values.meta_keyword}
                      placeholder="Enter Meta Keywords"
                      onChange={handleChange}
                      className={`shadow-none ${errors?.meta_keyword && touched.meta_keyword ? 'error' : 'correct'}`}
                      rows={4}
                    />
                    {touched.meta_keyword && errors?.meta_keyword && (
                      <ValidationFeedback title={errors?.meta_keyword} />
                    )}
                  </Col>
                </Row>


                {categoryAttributes.length > 0 && (
                  <>
                    <hr />
                    <div className="mb-4 text-center common-popup-heading">
                      <h4 className="text-capitalize mb-2 fw-medium">Attributes</h4>
                      <p>Add Attribute and value for the product</p>
                    </div>

                    {categoryAttributes.map((attributeGroup) => (
                      <div key={attributeGroup.id} className="mb-4">
                        <h5 className="mb-2">{attributeGroup.attribute_group_name}</h5>
                        <Row>
                          {attributeGroup.attribute_groups_values.map((value) => (
                            <Col md={4} key={value.id} className="mb-2">
                              <Form.Check
                                type="checkbox"
                                id={`attribute-${attributeGroup.id}-${value.id}`}
                                label={value.attribute_group_value}
                                checked={
                                  selectedAttributes[attributeGroup.id]?.includes(value.id) || false
                                }
                                onChange={() => handleAttributeChange(attributeGroup.id, value.id)}
                              />
                              {/* <Form.Check
                                type="checkbox"
                                id={`attribute-${attributeGroup.id}-${value.id}`}
                                label={value.attribute_group_value}
                                multiple
                                checked={
                                  selectedAttributes[attributeGroup.id]?.includes(value.id) || false
                                }
                                onChange={() => handleAttributeChange(attributeGroup.id, value.id)}
                              /> */}
                            </Col>
                          ))}
                        </Row>
                      </div>
                    ))}
                  </>
                )}

                <hr />
                <div className="mb-4 text-center common-popup-heading">
                  <h4 className="text-capitalize mb-2 fw-medium">Images</h4>
                  <p>Add images of the product</p>
                </div>

                <Row>
                  <Col md={12} className="mb-4">
                    <CommonFormLabel htmlFor="product_images" title="Product Images" />
                    <Input
                      id="product_images"
                      name="product_images"
                      type="file"
                      onChange={handleImageChange}
                      className={`shadow-none`}
                      multiple={true}
                    />
                    {touched.product_images && errors?.product_images && (
                      <ValidationFeedback title={errors?.product_images} />
                    )}
                  </Col>
                </Row>

                {productImages.length > 0 && (
                <div className="mb-4 common-popup-heading">
                  <p className="mb-4">Selected Images</p>
                  <Row>
                    {productImages.map((image, index) => (
                      <Col key={index} md={4} className="mb-4">
                        <div className="image-preview text-center mb-4">
                          <img
                            src={URL.createObjectURL(image)}
                            alt={`Preview ${index}`}
                            className="img-fluid rounded"
                            style={{ maxWidth: '120px', maxHeight: '120px', height: '120px', width: '120px' }}
                          />
                          <div className="d-flex align-items-center justify-content-center gap-3 common-popup-btn">
                            <button
                              type="button"
                              className="btn btn-danger btn-sm mt-2"
                              onClick={() => handleRemoveImage(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              )}

                <div className="d-flex align-items-center justify-content-center gap-3 common-popup-btn">
                  <CommonButton title={isSubmitting ? "Adding..." : "Add"} type="submit" disabled={isSubmitting} className="btn-primary primary-shadow" />
                  <CommonButton title="Cancel" onClick={handleModalClose} className="btn-muted" />
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}
