import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSettings } from './settingSlice';
import { 
  Container,
  Card,
  Row,
  Col,
  // Toast
} from 'react-bootstrap';
import { TaskEdit02Icon } from '../../assets/icons';
import CommonButton from '../../components/commonButton';
import { useLoader } from '../../context/LoaderContext';
import SettingEdit from './SettingEdit';

const SettingList = () => {
  const dispatch = useDispatch();
  const { setLoading } = useLoader();
  const { settings } = useSelector((state) => state.settings);
  // const [showSnackbar, setShowSnackbar] = useState(false);
  // const [snackbarMessage, setSnackbarMessage] = useState('');
  // const [snackbarVariant, setSnackbarVariant] = useState('success');
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      setLoading(true);
      try {
        await dispatch(getSettings());
      } catch (error) {
        console.error('Error fetching settings:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchSettings();
  }, [dispatch, setLoading]);

  const handleEdit = () => {
    setShowEditModal(true);
  };

  return (
    <>
      <Container fluid className="px-0">
        <SettingEdit
          show={showEditModal}
          // onHide={() => setShowEditModal(false)}
          handleClose={() => setShowEditModal(false)}
        />
        {/* <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
          style={{ position: 'fixed', top: 20, right: 20, zIndex: 1000 }}
        >
          <Toast.Body style={{ color: 'white' }}>{snackbarMessage}</Toast.Body>
        </Toast> */}

        <Card className="mb-4">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">Settings</h5>
            <CommonButton
              icon={<TaskEdit02Icon width={16} height={16} color="#ffffff" strokeWidth="2" />}
              title="Edit"
              className="btn-primary common-one-shadow"
              onClick={() => handleEdit()}
            />
          </Card.Header>
          <Card.Body>
            <Row className="gy-4">
              <Col md={6}>
                <strong style={{fontSize:"16px", fontWeight:"600" }}>Domain Name:</strong>
                <p>{settings.domain_name || '-'}</p>
              </Col>
              <Col md={6}>
                <strong style={{fontSize:"16px", fontWeight:"600" }}>Customer Care Number:</strong>
                <p>{settings.customer_care_no || '-'}</p>
              </Col>
              <Col md={6}>
                <strong style={{fontSize:"16px", fontWeight:"600" }}>Company Name:</strong>
                <p>{settings.company_name || '-'}</p>
              </Col>
              <Col md={6}>
                <strong style={{fontSize:"16px", fontWeight:"600" }}>Organization Type:</strong>
                <p>{settings.organization_type?.name || '-'}</p>
              </Col>
              <Col md={6}>
                <strong style={{fontSize:"16px", fontWeight:"600" }}>Business Type:</strong>
                <p>{settings.business_type?.name || '-'}</p>
              </Col>
              <Col md={6}>
                <strong style={{fontSize:"16px", fontWeight:"600" }}>CEO Name:</strong>
                <p>{settings.ceo_name || '-'}</p>
              </Col>
              <Col md={6}>
                <strong style={{fontSize:"16px", fontWeight:"600" }}>Website Email:</strong>
                <p style={{marginRight: '8rem' }}>{settings.website_email || '-'}</p>
              </Col>
              <Col md={6}>
                <strong style={{fontSize:"16px", fontWeight:"600" }}>Address:</strong>
                <p style={{marginRight: '8rem' }}>{settings.address || '-'}</p>
              </Col>
              <Col md={6}>
                <strong style={{fontSize:"16px", fontWeight:"600" }}>Description:</strong>
                <p style={{marginRight: '8rem' }}>{settings.description || '-'}</p>
              </Col>
            </Row>

            <hr />

            <Row className="gy-4">
              <Col md={6}>
                <strong style={{fontSize:"16px", fontWeight:"600" }}>Facebook Link:</strong>
                <p>{settings.facebook_link || '-'}</p>
              </Col>
              <Col md={6}>
                <strong style={{fontSize:"16px", fontWeight:"600" }}>Instagram Link:</strong>
                <p>{settings.instagram_link || '-'}</p>
              </Col>
              <Col md={6}>
                <strong style={{fontSize:"16px", fontWeight:"600" }}>Twitter Link:</strong>
                <p>{settings.twitter_link || '-'}</p>
              </Col>
              <Col md={6}>
                <strong style={{fontSize:"16px", fontWeight:"600" }}>Pinterest Link:</strong>
                <p>{settings.pinterest_link || '-'}</p>
              </Col>
              <Col md={6}>
                <strong style={{fontSize:"16px", fontWeight:"600" }}>LinkedIn Link:</strong>
                <p>{settings.linkedin_link || '-'}</p>
              </Col>
              <Col md={6}>
                <strong style={{fontSize:"16px", fontWeight:"600" }}>YouTube Link:</strong>
                <p>{settings.youtube_link || '-'}</p>
              </Col>
              <Col md={6}>
                <strong style={{fontSize:"16px", fontWeight:"600" }}>Google Tag:</strong>
                <p>{settings.gtag || '-'}</p>
              </Col>
            </Row>

            <hr />

            <Row className="gy-4">
              <Col md={6}>
                {settings.logo && (
                  <div>
                    <strong style={{fontSize:"16px", fontWeight:"600" }}>Logo</strong>
                    <div className="mt-2">
                      <img
                        src={`${settings.url}/${settings.logo}`}
                        alt="Logo"
                        className="img-fluid"
                        style={{ maxHeight: '200px', maxWidth: '100%' }}
                      />
                    </div>
                  </div>
                )}
              </Col>
              <Col md={6}>
                {settings.favicon_icon && (
                  <div>
                    <strong style={{fontSize:"16px", fontWeight:"600" }}>Favicon Icon</strong>
                    <div className="mt-2">
                      <img
                        src={`${settings.url}/${settings.favicon_icon}`}
                        alt="Favicon Icon"
                        className="img-fluid"
                        style={{ maxHeight: '100px', maxWidth: '100%' }}
                      />
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {/* Modal for editing settings */}
        
      </Container>
    </>
  );
};

export default SettingList;
