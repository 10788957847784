import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import Input from "../../components/input";
import ValidationFeedback from "../../components/validationFeedback";
import FileInput from "../../components/fileInput";
import { useParams, useNavigate } from "react-router-dom";
import CommonButton from "../../components/commonButton";
import { updateBlog, getBlogDetails } from "../../modules/Blog/blogSlice";
import { useLoader } from "../../context/LoaderContext";
import { blogUpdateSchema } from "../../schema/validationSchemas";
import CommonFormLabel from "../../components/formLabel";
import { Toast, Form, Row, Col, Card } from "react-bootstrap";
import Editor from "../../components/commonEditor";
import { useDispatch } from "react-redux";

export default function BlogEditNew() {
  const { id: blogId } = useParams();
  const navigate = useNavigate();
  const { setLoading } = useLoader();
  const dispatch = useDispatch();

  const [blog, setBlog] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newImagePreview, setNewImagePreview] = useState(null); // State for new selected image preview

  useEffect(() => {
    const fetchBlog = async () => {
      setLoading(true);
      try {
        const result = await dispatch(getBlogDetails(blogId)).unwrap();
        setBlog(result.data);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching blog:", error);
        setSnackbarMessage("Failed to load blog details.");
        setSnackbarVariant("danger");
        setShowSnackbar(true);
      } finally {
        setLoading(false);
      }
    };

    if (blogId) fetchBlog();
  }, [blogId, dispatch, setLoading]);

  const handleCancel = () => {
    navigate("/blogs");
  };

  const handleImageChange = (file, setFieldValue) => {
    if (file) {
      setNewImagePreview(URL.createObjectURL(file)); // Generate new image preview
      setFieldValue("blog_image", file); // Update Formik's blog_image field
    }
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("id", blogId);
    formData.append("_method", "PUT");
    formData.append("blog_title", values.blog_title);
    formData.append("blog_description", values.blog_description);
    formData.append("author", values.author);
    if (values.blog_image) formData.append("blog_image", values.blog_image);

    try {
      const res = await dispatch(updateBlog(formData)).unwrap();
      if (res.success) {
        localStorage.setItem("blog_notify_message", res.message);
        setSnackbarMessage(res.message);
        setSnackbarVariant("success");
        setShowSnackbar(true);
        navigate("/blogs");
      } else {
        setErrors(res.data);
        setSnackbarMessage(res.message);
        setSnackbarVariant("danger");
        setShowSnackbar(true);
      }
    } catch (error) {
      setSnackbarMessage("Failed to update blog!");
      setSnackbarVariant("danger");
      setShowSnackbar(true);
    } finally {
      setSubmitting(false);
      setIsSubmitting(false);
    }
  };

  if (!blog)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "60vh" }}
      >
        <h2>Loading Blog Details...</h2>
      </div>
    );

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>

      <Formik
        initialValues={{
          blog_title: blog?.blog_title || "",
          author: blog?.author || "",
          blog_image: "",
          blog_description: blog?.blog_description || "",
        }}
        validationSchema={blogUpdateSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          errors,
          touched,
          handleChange,
          handleSubmit,
          values,
          setFieldValue,
        }) => (
          <Form
            className="bg-white p-4 rounded-2 shadow-sm"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
                <Row>
                <Col md={6} className="mb-4">
                    <CommonFormLabel htmlFor="blog_title" title="Blog Title" />
                    <Input
                    id="blog_title"
                    name="blog_title"
                    type="text"
                    placeholder="Enter Blog Title"
                    onChange={handleChange}
                    value={values.blog_title}
                    className={`shadow-none ${
                        errors?.blog_title && touched.blog_title ? "error" : "correct"
                    }`}
                    />
                    {touched.blog_title && errors?.blog_title && (
                    <ValidationFeedback title={errors?.blog_title} />
                    )}
                </Col>

                <Col md={6} className="mb-4">
                    <CommonFormLabel htmlFor="author" title="Author" />
                    <Input
                    id="author"
                    name="author"
                    type="text"
                    placeholder="Enter Author Name"
                    onChange={handleChange}
                    value={values.author}
                    className={`shadow-none ${
                        errors?.author && touched.author ? "error" : "correct"
                    }`}
                    />
                    {touched.author && errors?.author && (
                    <ValidationFeedback title={errors?.author} />
                    )}
                </Col>
                </Row>

                <div className="mb-4">
                <CommonFormLabel htmlFor="blog_description" title="Description" />
                <Editor
                    value={values.blog_description}
                    onChange={(content) =>
                    setFieldValue("blog_description", content)
                    }
                    height="250px"
                />
                {touched.blog_description && errors?.blog_description && (
                    <ValidationFeedback title={errors?.blog_description} />
                )}
                </div>
            </Card>


            <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
                <div className="mb-4">
                <CommonFormLabel htmlFor="blog_image" title="Upload New Image" />
                <FileInput
                    name="blog_image"
                    onChange={(file) => handleImageChange(file, setFieldValue)}
                    error={errors?.blog_image}
                    touched={touched.blog_image}
                    inputType="image"
                />
                {touched.blog_image && errors?.blog_image && (
                    <ValidationFeedback title={errors?.blog_image} />
                )}
                </div>

                {newImagePreview && (
                    <div className="">
                            <CommonFormLabel
                                htmlFor="new_selected_image"
                                title="New selected Image"
                            />
                        <div>
                            <img
                                src={newImagePreview}
                                alt="New Selected"
                                className="img-fluid rounded border"
                                style={{ width: "200px", height: "auto" }}
                            />
                        </div>
                    </div>
                )}
            </Card >

            <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
                <div className="">
                    <CommonFormLabel
                        htmlFor="blog_image_preview"
                        title="Uploaded Image"
                    />
                    <div>
                    {blog?.blog_image && (
                        <img
                            src={`${blog?.url}/${blog?.blog_image}`}    
                            alt="Uploaded Blog"
                            className="img-fluid rounded border"
                            style={{ width: "200px", height: "auto" }}
                        />
                        ) }
                    </div>
                </div>
            </Card>

            <div className="d-flex align-items-center justify-content-end gap-3 common-popup-btn">
              <CommonButton
                title={isSubmitting ? "Updating..." : "Update"}
                type="submit"
                disabled={isSubmitting}
                className="btn-primary primary-shadow"
              />
              <CommonButton
                title="Cancel"
                onClick={handleCancel}
                className="btn-muted"
              />
            </div>
          </Form>
        )}
      </Formik>
      </>
  );
}