import React from "react";
import { Form } from "react-bootstrap";
import "./validationFeedBack.css";

export default function ValidationFeedback({ title }) {
  return (
    <Form.Control.Feedback className="mt-1 d-block common-feedback">
      {title}
    </Form.Control.Feedback>
  );
}
