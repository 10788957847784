import { apiHelper } from '../utils/apiHelper';

const API_URL = `${process.env.REACT_APP_API_URL}/api/org/enquiry`;

// const headers = {
//   "Content-Type": "multipart/form-data",
//   "Accept": "application/json"
// };

export const fetchInquiries = (payload) => apiHelper(API_URL, 'GET', payload);
export const deleteInquiryApi = (inquiryId) => apiHelper(`${API_URL}/${inquiryId}`, 'DELETE');
export const changeStatus = (inquiryId) => apiHelper(`${API_URL}/change-status/${inquiryId}`, 'POST');

