import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loginApi, logOutApi, userDetailsApi, registerUserApi } from '../../api/authApi';

// Thunk for login API call
export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await loginApi(payload);
      return response.data;  // Success case
    } catch (error) {
      return rejectWithValue(error.response.data);  // Error case
    }
  }
);

export const registerUser = createAsyncThunk(
  'auth/registerUser',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await registerUserApi(payload);
      return response;  // Success case
    } catch (error) {
      return rejectWithValue(error.response.data);  // Error case
    }
  }
);

export const logOut = createAsyncThunk(
  'auth/logOutUser',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await logOutApi(payload);
      return response;  // Success case
    } catch (error) {
      return rejectWithValue(error.response.data);  // Error case
    }
  }
);

export const userDetails = createAsyncThunk(
  'user/details',
  async ({ payload, token }, { rejectWithValue }) => {
      const response = await userDetailsApi.get('/api/customer/me', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    isAuthenticated: false,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.user = action.payload;
        localStorage.setItem('userData', JSON.stringify(action.payload));
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(registerUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(logOut.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(logOut.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(logOut.fulfilled, (state, action) => {
        state.isAuthenticated = false;
        state.user = null;
      })
      .addCase(userDetails.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(userDetails.rejected, (state, action) => {
        state.user = null;
      });


      // builder.addCase(userDetails.fulfilled, (state, { payload }) => {
      //   if (payload.data.success === true) {
      //     state.isAuthenticated = true;
      //     state.user = payload.data.data;
      //   }
      // })
      // builder.addCase(userDetails.rejected, (state, { payload }) => {
      //   if (payload === undefined) {
      //     state.isAuthenticated = false;
      //     state.user = null;
      //   }
      // });

  },
});

// export const { logout } = authSlice.actions;
export default authSlice.reducer;
