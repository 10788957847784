import React from "react";
import { Form } from "react-bootstrap";
import "./index.css";

export default function TextArea({
  placeholder,
  value,
  id,
  rows = 4,
  onChange,
  className,
  maxLength,
  minLength,
  style,
  ariaLabel,
  ariaDescribedby,
  isValid,
  onBlur,
  required,
}) {
  return (
    <Form.Control
      as="textarea"
      placeholder={placeholder}
      value={value}
      id={id}
      rows={rows}
      onChange={onChange}
      maxLength={maxLength}
      minLength={minLength}
      aria-label={ariaLabel}
      aria-describedby={ariaDescribedby}
      isValid={isValid}
      onBlur={onBlur}
      required={required}
      className={`common-textarea ${className}`}
      style={style}
    />
  );
}
