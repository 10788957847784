// import React from "react";
// import { Pagination } from 'react-bootstrap';
// import { ArrowLeftIcon, ArrowRightIcon } from "../../icons/icons";

import React from "react";
import { Pagination } from 'react-bootstrap';
import { ArrowLeftIcon, ArrowRightIcon } from "../../assets/icons";
import "./commonPagination.css";

export default function CommonPagination({ totalItems, itemsPerPage, currentPage, onPageChange }) {
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            onPageChange(page);
        }
    };

    const renderPaginationItems = () => {
        let items = [];

        if (totalPages <= 5) {
            for (let page = 1; page <= totalPages; page++) {
                items.push(
                    <li
                        key={page}
                        className={page === currentPage ? "active" : ""}
                        onClick={() => handlePageChange(page)}
                    >
                        {page}
                    </li>
                );
            }
        } else {
            items.push(
                <li
                    key={1}
                    className={currentPage === 1 ? "active" : ""}
                    onClick={() => handlePageChange(1)}
                >
                    1
                </li>
            );

            if (currentPage > 3) {
                items.push(<Pagination.Ellipsis key="start-ellipsis" />);
            }

            if (currentPage > 2) {
                items.push(
                    <li
                        key={currentPage - 1}
                        onClick={() => handlePageChange(currentPage - 1)}
                    >
                        {currentPage - 1}
                    </li>
                );
            }

            if (currentPage !== 1 && currentPage !== totalPages) {
                items.push(
                    <li key={currentPage} className='active'>
                        {currentPage}
                    </li>
                );
            }

            if (currentPage < totalPages - 1) {
                items.push(
                    <li
                        key={currentPage + 1}
                        onClick={() => handlePageChange(currentPage + 1)}
                    >
                        {currentPage + 1}
                    </li>
                );
            }

            if (currentPage < totalPages - 2) {
                items.push(<Pagination.Ellipsis key="end-ellipsis" />);
            }

            items.push(
                <li
                    key={totalPages}
                    className={currentPage === totalPages ? "active" : ""}
                    onClick={() => handlePageChange(totalPages)}
                >
                    {totalPages}
                </li>
            );
        }

        return items;
    };
    return <ul className=" list-unstyled mb-0 d-flex align-items-center custom-pagination">
        {/* <Pagination.Prev
            className="border-0 outline-none prev-btn"

            disabled={currentPage === 1}
        >

        </Pagination.Prev> */}
        <li className={`prev-btn ${currentPage === 1 && 'disabled'}`} onClick={() => handlePageChange(currentPage - 1)}>
            <ArrowLeftIcon width={18} height={18} color="#444050" strokeWidth="1.5" />
        </li>
        {renderPaginationItems()}
        {/* <Pagination.Next
            className="border-0 outline-none "

            disabled={currentPage === totalPages}
        >

        </Pagination.Next> */}
        <li className={`next-btn ${currentPage === totalPages && 'disabled'}`} onClick={() => handlePageChange(currentPage + 1)} >
            <ArrowRightIcon width={18} height={18} color="#444050" strokeWidth="1.5" />
        </li>
    </ul>;
}
