import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchProducts, createProduct, fetchProduct, modifyProduct, deleteProductApi, changeStatus } from '../../api/productApi';

export const getProducts = createAsyncThunk('product/getProducts', async ({payload}, { rejectWithValue }) => {
  try {
    const response = await fetchProducts(payload);
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const addProduct = createAsyncThunk('product/addProduct', async (payload, { rejectWithValue }) => {
  try {
    const response = await createProduct(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getProductDetails = createAsyncThunk('product/getProductDetails', async (productId, { rejectWithValue }) => {
  try {
    const response = await fetchProduct(productId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const updateProduct = createAsyncThunk('product/updateProduct', async (payload, { rejectWithValue }) => {
  try {
    const response = await modifyProduct(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deleteProduct = createAsyncThunk('product/deleteProduct', async (productId, { rejectWithValue }) => {
  try {
    const response = await deleteProductApi(productId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const changeActiveStatus = createAsyncThunk('product/changeDefaultStatus', async (productId, { rejectWithValue }) => {
  try {
    const response = await changeStatus(productId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const productSlice = createSlice({
  name: 'product',
  initialState: {
    products: [],
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.products = action.payload;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
});

export default productSlice.reducer;
