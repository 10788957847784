import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../modules/Auth/login";
// import Register from "../modules/Auth/register";
import Dashboard from "../modules/Dashboard";
import CategoryList from "../modules/Category/CategoryList";
// import SubCategoryList from "../modules/SubCategory/SubCategoryList";
import BlogList from "../modules/Blog/BlogList";
import BlogAdd from "../modules/Blog/BlogAddNew";
import BlogEdit from "../modules/Blog/BlogEditNew";
import MarketAreaList from "../modules/MarketArea/MarketAreaList";
import GalleryList from "../modules/Gallery/GalleryList";
import SettingList from "../modules/Settings/SettingList";
import TestimonialList from "../modules/Testimonial/TestimonialList";
import TeamList from "../modules/Team/TeamList";
import InquiryList from "../modules/Inquiry/InquiryList";
import ProductList from "../modules/Product/ProductList";

import CommonLayout from "../layouts";
import { AuthProvider } from "../context/AuthContext";
import { LoaderProvider } from "../context/LoaderContext";
import Loader from "../components/loader";
import PrivateRouteLayout from './PrivateRouteLayout'; 

const AppRoutes = () => (
  <AuthProvider>
    <LoaderProvider>
      <Loader />
      <Router>
        <Routes>
          <Route exact path="/login" element={<Login />} />
          {/* <Route exact path="/register" element={<Register />} /> */}
            <Route path="/" element={<PrivateRouteLayout />}>
              <Route path="/" element={<CommonLayout />}>
                <Route exact path="/" element={<Dashboard />} />
                <Route exact path="/blogs" element={<BlogList />} />
                <Route exact path="/blog-add" element={<BlogAdd />} />
                <Route exact path="/blog-edit/:id" element={<BlogEdit />} />
                {/* <Route exact path="/blog-add" element={<BlogList />} /> */}
                <Route exact path="/market-areas" element={<MarketAreaList />} />
                <Route exact path="/galleries" element={<GalleryList />} />
                <Route exact path="/settings" element={<SettingList />} />
                <Route exact path="/categories" element={<CategoryList />} />
                {/* <Route exact path="/sub-categories" element={<SubCategoryList />} /> */}
                <Route exact path="/testimonials" element={<TestimonialList />} />
                <Route exact path="/teams" element={<TeamList />} />
                <Route exact path="/inquiries" element={<InquiryList />} />
                <Route exact path="/products" element={<ProductList />} />
              </Route>
          </Route>
        </Routes>
      </Router>
    </LoaderProvider>
  </AuthProvider>
);

export default AppRoutes;
