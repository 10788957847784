import "react-quill/dist/quill.snow.css";
import ReactQuill from 'react-quill';
import './Editor.css';

export default function Editor({
    value,
    onChange,
    height = "250px"
  }) {
    return (
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        className="custom-editor"
        style={{
          height: `calc(${height} + 42px)`, // 42px accounts for the toolbar height
          marginBottom: "4rem",
        }}
      />
    );
  }
