import { apiHelper } from '../utils/apiHelper';

const API_URL = `${process.env.REACT_APP_API_URL}/api/org/product`;

const headers = {
  "Content-Type": "multipart/form-data",
  "Accept": "application/json"
};

export const fetchProducts = (payload) => apiHelper(API_URL, 'GET', payload);
export const createProduct = (payload) => apiHelper(API_URL, 'POST', payload, headers);
export const fetchProduct = (productId) => apiHelper(`${API_URL}/${productId}`, 'GET');
export const modifyProduct = (payload) => apiHelper(`${API_URL}/${payload.get('id')}`, 'POST', payload, headers);
export const deleteProductApi = (productId) => apiHelper(`${API_URL}/${productId}`, 'DELETE');
export const changeStatus = (productId) => apiHelper(`${API_URL}/change-status/${productId}`, 'POST');
// export const getAllCategoriesApi = () => apiHelper(`${process.env.REACT_APP_API_URL}/api/org/get-all-categories`, 'GET');

