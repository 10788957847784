import { apiHelper } from '../utils/apiHelper';

const API_URL = `${process.env.REACT_APP_API_URL}/api/org/category`;

const headers = {
  "Content-Type": "multipart/form-data",
  "Accept": "application/json"
};

export const fetchCategories = (payload) => apiHelper(API_URL, 'GET', payload);
export const createCategory = (payload) => apiHelper(API_URL, 'POST', payload, headers);
export const fetchCategory = (categoryId) => apiHelper(`${API_URL}/${categoryId}`, 'GET');
export const modifyCategory = (payload) => apiHelper(`${API_URL}/${payload.get('id')}`, 'POST', payload, headers);
export const deleteCategoryApi = (categoryId) => apiHelper(`${API_URL}/${categoryId}`, 'DELETE');
export const getAllCategoriesApi = (payload) => apiHelper(`${process.env.REACT_APP_API_URL}/api/org/get-all-categories`, 'GET', payload);
export const getChildCategoriesApi = (payload) => apiHelper(`${process.env.REACT_APP_API_URL}/api/org/get-child-categories`, 'GET', payload);
export const getCategoryAttributesApi = (payload) => apiHelper(`${process.env.REACT_APP_API_URL}/api/org/get-category-attributes`, 'GET', payload);
export const changeStatus = (categoryId) => apiHelper(`${API_URL}/change-status/${categoryId}`, 'POST');