import React from 'react';
import { Table } from 'react-bootstrap';
import CommonActionDropDown from "../commonDropdown";
// import CommonButton from "../commonButton";
import { MoreVerticalIcon } from "../../assets/icons";
import StatusChip from '../statusChip';

const getNestedValue = (obj, path) => {
  const data = path.split('.').reduce((acc, key) => (acc ? acc[key] : null), obj);
  return data ? data : '-';
};

const DynamicTable = ({ columns, data, showActions, actions, url = null, showButton = false, onStatusChange, moduleName = null }) => {
  return (
    <Table responsive className="common-table">
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index}>{column.label}</th>
          ))}
          {showActions && <th>Actions</th>}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex} onClick={() => actions?.onEdit && actions.onEdit(row)} className='cursor-pointer'>
            {columns.map((column, colIndex) => (
              <td key={colIndex}>
                {column.type === 'image' ? (
                  <img
                    // src={url + "/" + row[column.key]}
                    src={url + "/" + getNestedValue(row, column.key)}  
                    alt={column.label}
                    style={{ maxWidth: '37px', height: '37px' }}
                  />
                ) : column.type === 'date' ? (
                  getNestedValue(row, column.key) === '-' ? (
                    '-'
                  ) : (
                    new Date(getNestedValue(row, column.key)).toLocaleDateString() === 'Invalid Date' ? (
                      '-'
                    ) : (
                      new Date(getNestedValue(row, column.key)).toLocaleTimeString('en-IN', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        // second: '2-digit',
                        hour12: true,
                      }).replace(', ', ' ').replace(/\//g, '-').replace(/(am|pm)$/i, (match) => match.toUpperCase())
                    )
                  )
                )  : column.type === 'status' ? (
                  // row[column.key] === 1 ? (
                    getNestedValue(row, column.key) === true ? (
                    <StatusChip
                      bg="chip-green"
                      title="Active"
                      className="text-capitalize"
                      onClick={(event) => {
                        event.stopPropagation();
                        onStatusChange && onStatusChange(row);
                      }}
                    />
                  ) : (
                    <StatusChip
                      bg="chip-red"
                      title="Deactivate"
                      className="text-capitalize"
                      onClick={(event) => {
                        event.stopPropagation();
                        onStatusChange && onStatusChange(row);
                      }}
                    />
                  )
                ) : column.type === 'default' ? (
                    getNestedValue(row, column.key) === true ? (
                    <StatusChip
                      bg="chip-green"
                      title="True"
                      className="text-capitalize"
                    />
                  ) : (
                    <StatusChip
                      bg="chip-red"
                      title="False"
                      className="text-capitalize"
                      onClick={(event) => {
                        event.stopPropagation();
                        onStatusChange && onStatusChange(row.id);
                      }}
                    />
                  )
                ) : (
                  // row[column.key]
                  getNestedValue(row, column.key)
                )}
              </td>
            ))}
            {showActions && (
              <td onClick={(event) => {
                event.stopPropagation();
              }}>
                <CommonActionDropDown
                  icon={<MoreVerticalIcon width={20} height={20} color="#6D6B77" strokeWidth="4" />}
                  firstTitle={actions?.showEdit ? 'Edit' : null}
                  firstHref="#" 
                  // firstOnClick={() => actions?.onEdit && actions.onEdit(row)}
                  firstOnClick={(event) => {
                    event.stopPropagation();
                    actions?.onEdit && actions.onEdit(row);
                  }}
                  secondTitle={actions?.showView ? 'View' : null}
                  secondHref="#" 
                  // secondOnClick={() => actions?.onView && actions.onView(row)}
                  secondOnClick={(event) => {
                    event.stopPropagation();
                    actions?.onView && actions.onView(row);
                  }}
                  thirdTitle={actions?.showDelete && (moduleName === 'category' ? (row.parent_category_id !== undefined && row.parent_category_id !== null) : true) ? 'Delete' : null}
                  thirdHref="#" 
                  // thirdOnClick={() => actions?.onDelete && actions.onDelete(row)}
                  thirdOnClick={(event) => {
                    event.stopPropagation();
                    actions?.onDelete && actions.onDelete(row);
                  }}
                />
              </td>
            )}
            {/* {showButton && row.is_default === false && (
              <td style={{ width: '100px' }}>
                <CommonButton
                  title="Make Default"
                  className="btn-primary common-one-shadow"
                  onClick={() => onStatusChange && onStatusChange(row.id)}
                />
              </td>
            )} */}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DynamicTable;


