import { apiHelper } from '../utils/apiHelper';

const API_URL = `${process.env.REACT_APP_API_URL}/api/org/settings`;
const UPDATE_API_URL = `${process.env.REACT_APP_API_URL}/api/org/update-settings`;

const headers = {
  "Content-Type": "multipart/form-data",
  "Accept": "application/json"
};

export const fetchSettings = (payload) => apiHelper(API_URL, 'GET', payload);
export const modifySettings = (payload) => apiHelper(UPDATE_API_URL, 'POST', payload, headers);

