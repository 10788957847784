import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchTestimonials, createTestimonial, fetchTestimonial, modifyTestimonial, deleteTestimonialApi, changeStatus } from '../../api/testimonialApi';

export const getTestimonials = createAsyncThunk('testimonial/getTestimonials', async ({payload}, { rejectWithValue }) => {
  try {
    const response = await fetchTestimonials(payload);
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const addTestimonial = createAsyncThunk('testimonial/addTestimonial', async (payload, { rejectWithValue }) => {
  try {
    const response = await createTestimonial(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getTestimonialDetails = createAsyncThunk('testimonial/getTestimonialDetails', async (testimonialId, { rejectWithValue }) => {
  try {
    const response = await fetchTestimonial(testimonialId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const updateTestimonial = createAsyncThunk('testimonial/updateTestimonial', async (payload, { rejectWithValue }) => {
  try {
    const response = await modifyTestimonial(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deleteTestimonial = createAsyncThunk('testimonial/deleteTestimonial', async (testimonialId, { rejectWithValue }) => {
  try {
    const response = await deleteTestimonialApi(testimonialId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const changeActiveStatus = createAsyncThunk('testimonial/changeDefaultStatus', async (testimonialId, { rejectWithValue }) => {
  try {
    const response = await changeStatus(testimonialId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const testimonialSlice = createSlice({
  name: 'testimonial',
  initialState: {
    testimonials: [],
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTestimonials.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getTestimonials.fulfilled, (state, action) => {
        state.isLoading = false;
        state.testimonials = action.payload;
      })
      .addCase(getTestimonials.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
});

export default testimonialSlice.reducer;
