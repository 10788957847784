import React, { useState, useCallback } from "react";
import CommonFormLabel from "../../components/formLabel";
import Input from "../../components/input";
import Textarea from "../../components/commonTextArea";
import ValidationFeedback from "../../components/validationFeedback";
import CommonButton from "../../components/commonButton";
import FileInput from "../../components/fileInput";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Toast, Form, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import { updateSettings, getSettings } from "../../modules/Settings/settingSlice";
import { settingsSchema } from "../../schema/validationSchemas";
import { Cancel01Icon } from "../../assets/icons";

export default function Edit({
  show,
  handleClose,
}) {
  const { settings } = useSelector((state) => state.settings);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const handleModalClose = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setIsSubmitting(true);

    const formData = new FormData();

    Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
    });

    await dispatch(updateSettings(formData))
      .then((res) => {
        if (res.payload && res.payload.success) {
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("success");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
          handleClose();
          dispatch(getSettings());
        } else {
          setErrors(res.payload.data);
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("danger");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        setSnackbarMessage("Failed to update settings!");
        setSnackbarVariant("danger");
        setShowSnackbar(true);
        setSubmitting(false);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>

      <Modal
        show={show}
        size="lg"
        centered
        dialogClassName="common-popup-dialog"
        contentClassName="common-popup-content"
        backdropClassName="common-popup-backdrop"
        onHide={handleModalClose}
      >
        <Modal.Body className="common-popup-body">
          <div
            className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center common-popup-close-btn"
            onClick={handleModalClose}
          >
            <Cancel01Icon width={16} height={16} color="#BCBBC1" strokeWidth="2.5" />
          </div>
          <div className="mb-4 text-center common-popup-heading">
            <h4 className="text-capitalize mb-2 fw-medium">Edit Settings</h4>
            <p>Edit existing settings</p>
          </div>

          {settings && (
            <Formik
              initialValues={{
                // domain_name: settings.domain_name || "",
                customer_care_no: settings.customer_care_no || "",
                ceo_name: settings.ceo_name || "",
                company_name: settings.company_name || "",
                logo: "",
                favicon_icon: "",
                description: settings.description || "",
                website_email: settings.website_email || "",
                address: settings.address || "",
                facebook_link: settings.facebook_link || "",
                twitter_link: settings.twitter_link || "",
                linkedin_link: settings.linkedin_link || "",
                instagram_link: settings.instagram_link || "",
                youtube_link: settings.youtube_link || "",
                pinterest_link: settings.pinterest_link || "",
                gtag: settings.gtag || "",
              }}
              validationSchema={settingsSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, handleChange, handleSubmit, values, setFieldValue }) => (
                <Form onSubmit={handleSubmit} encType="multipart/form-data">
                    <Row>
                        {/* <Col md={6} className="mb-4">
                            <CommonFormLabel htmlFor="domain_name" title="Domain Name" />
                            <Input
                                id="domain_name"
                                name="domain_name"
                                type="text"
                                placeholder="Enter Domain Name"
                                onChange={handleChange}
                                value={values.domain_name}
                                className={`shadow-none ${errors?.domain_name && touched.domain_name ? 'error' : 'correct'}`}
                            />
                            {touched.domain_name && errors?.domain_name && (
                                <ValidationFeedback title={errors?.domain_name} />
                            )}
                        </Col> */}

                        <Col md={6} className="mb-4">
                            <CommonFormLabel htmlFor="customer_care_no" title="Customer Care No" />
                            <Input
                                id="customer_care_no"
                                name="customer_care_no"
                                type="text"
                                placeholder="Enter Customer Care No"
                                onChange={handleChange}
                                value={values.customer_care_no}
                                className={`shadow-none ${errors?.customer_care_no && touched.customer_care_no ? 'error' : 'correct'}`}
                            />
                            {touched.customer_care_no && errors?.customer_care_no && (
                                <ValidationFeedback title={errors?.customer_care_no} />
                            )}
                        </Col>
                        <Col md={6} className="mb-4">
                            <CommonFormLabel htmlFor="website_email" title="Website Email" />
                            <Input
                                id="website_email"
                                name="website_email"
                                type="text"
                                placeholder="Enter Website Email"
                                onChange={handleChange}
                                value={values.website_email}
                                className={`shadow-none ${errors?.website_email && touched.website_email ? 'error' : 'correct'}`}
                            />
                            {touched.website_email && errors?.website_email && (
                                <ValidationFeedback title={errors?.website_email} />
                            )}
                        </Col>
                    </Row>

                    <Row>
                      <Col md={6} className="mb-4">
                          <CommonFormLabel htmlFor="ceo_name" title="Ceo Name" />
                          <Input
                              id="ceo_name"
                              name="ceo_name"
                              type="text"
                              placeholder="Enter Ceo Name"
                              onChange={handleChange}
                              value={values.ceo_name}
                              className={`shadow-none ${errors?.ceo_name && touched.ceo_name ? 'error' : 'correct'}`}
                          />
                          {touched.ceo_name && errors?.ceo_name && (
                              <ValidationFeedback title={errors?.ceo_name} />
                          )}
                      </Col>

                      <Col md={6} className="mb-4">
                          <CommonFormLabel htmlFor="company_name" title="Company Name" />
                          <Input
                              id="company_name"
                              name="company_name"
                              type="text"
                              placeholder="Enter Company Name"
                              onChange={handleChange}
                              value={values.company_name}
                              className={`shadow-none ${errors?.company_name && touched.company_name ? 'error' : 'correct'}`}
                          />
                          {touched.company_name && errors?.company_name && (
                              <ValidationFeedback title={errors?.company_name} />
                          )}
                      </Col>
                    </Row>

                    <Row>
                        <Col md={12} className="mb-4">
                            <CommonFormLabel htmlFor="description" title="Description" />
                            <Textarea
                                id="description"
                                name="description"
                                placeholder="Enter Description"
                                onChange={handleChange}
                                value={values.description}
                                className={`shadow-none ${errors?.description && touched.description ? 'error' : 'correct'}`}
                                rows={4}
                            />
                            {touched.description && errors?.description && (
                            <ValidationFeedback title={errors?.description} />
                            )}
                        </Col>
                        <Col md={12} className="mb-4">
                            <CommonFormLabel htmlFor="address" title="Address" />
                            <Textarea
                                id="address"
                                name="address"
                                placeholder="Enter Address"
                                onChange={handleChange}
                                value={values.address}
                                className={`shadow-none ${errors?.address && touched.address ? 'error' : 'correct'}`}
                                rows={4}
                            />
                            {touched.address && errors?.address && (
                                <ValidationFeedback title={errors?.address} />
                            )}
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6} className="mb-4">
                            <CommonFormLabel htmlFor="facebook_link" title="Facebook Link" />
                            <Input
                                id="facebook_link"
                                name="facebook_link"
                                type="text"
                                placeholder="Enter Facebook Link"
                                onChange={handleChange}
                                value={values.facebook_link}
                                className={`shadow-none ${errors?.facebook_link && touched.facebook_link ? 'error' : 'correct'}`}
                            />
                            {touched.facebook_link && errors?.facebook_link && (
                                <ValidationFeedback title={errors?.facebook_link} />
                            )}
                        </Col>
                        <Col md={6} className="mb-4">
                            <CommonFormLabel htmlFor="twitter_link" title="Twitter Link" />
                            <Input
                                id="twitter_link"
                                name="twitter_link"
                                type="text"
                                placeholder="Enter Twitter Link"
                                onChange={handleChange}
                                value={values.twitter_link}
                                className={`shadow-none ${errors?.twitter_link && touched.twitter_link ? 'error' : 'correct'}`}
                            />
                            {touched.twitter_link && errors?.twitter_link && (
                                <ValidationFeedback title={errors?.twitter_link} />
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="mb-4">
                            <CommonFormLabel htmlFor="instagram_link" title="Instagram Link" />
                            <Input
                                id="instagram_link"
                                name="instagram_link"
                                type="text"
                                placeholder="Enter Instagram Link"
                                onChange={handleChange}
                                value={values.instagram_link}
                                className={`shadow-none ${errors?.instagram_link && touched.instagram_link ? 'error' : 'correct'}`}
                            />
                            {touched.instagram_link && errors?.instagram_link && (
                                <ValidationFeedback title={errors?.instagram_link} />
                            )}
                        </Col>
                        <Col md={6} className="mb-4">
                            <CommonFormLabel htmlFor="linkedin_link" title="Linkedin Link" />
                            <Input
                                id="linkedin_link"
                                name="linkedin_link"
                                type="text"
                                placeholder="Enter Linkedin Link"
                                onChange={handleChange}
                                value={values.linkedin_link}
                                className={`shadow-none ${errors?.linkedin_link && touched.linkedin_link ? 'error' : 'correct'}`}
                            />
                            {touched.linkedin_link && errors?.linkedin_link && (
                                <ValidationFeedback title={errors?.linkedin_link} />
                            )}
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6} className="mb-4">
                            <CommonFormLabel htmlFor="pinterest_link" title="Pinterest Link" />
                            <Input
                                id="pinterest_link"
                                name="pinterest_link"
                                type="text"
                                placeholder="Enter Pinterest Link"
                                onChange={handleChange}
                                value={values.pinterest_link}
                                className={`shadow-none ${errors?.pinterest_link && touched.pinterest_link ? 'error' : 'correct'}`}
                            />
                            {touched.pinterest_link && errors?.pinterest_link && (
                                <ValidationFeedback title={errors?.pinterest_link} />
                            )}
                        </Col>
                        <Col md={6} className="mb-4">
                            <CommonFormLabel htmlFor="youtube_link" title="Youtube Link" />
                            <Input
                                id="youtube_link"
                                name="youtube_link"
                                type="text"
                                placeholder="Enter Youtube Link"
                                onChange={handleChange}
                                value={values.youtube_link}
                                className={`shadow-none ${errors?.youtube_link && touched.youtube_link ? 'error' : 'correct'}`}
                            />
                            {touched.youtube_link && errors?.youtube_link && (
                                <ValidationFeedback title={errors?.youtube_link} />
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="mb-4">
                            <CommonFormLabel htmlFor="gtag" title="Google Tag" />
                            <Textarea
                                id="gtag"
                                name="gtag"
                                placeholder="Enter Google Tag"
                                onChange={handleChange}
                                value={values.gtag}
                                className={`shadow-none ${errors?.gtag && touched.gtag ? 'error' : 'correct'}`}
                                rows={4}
                            />
                            {touched.gtag && errors?.gtag && (
                                <ValidationFeedback title={errors?.gtag} />
                            )}
                        </Col>
                    </Row>


                  {/* logo Image */}
                  <div className="mb-4">
                    <CommonFormLabel htmlFor="logo" title="Upload New Logo" />
                    <FileInput
                      name="logo"
                      onChange={(file) => setFieldValue("logo", file)}
                      error={errors?.logo}
                      touched={touched.logo}
                    />
                    {touched.logo && errors?.logo && (
                      <ValidationFeedback title={errors?.logo} />
                    )}
                  </div>

                  <div className="mb-4">
                    <CommonFormLabel htmlFor="favicon_icon" title="Upload New Favicon" />
                    <FileInput
                      name="favicon_icon"
                      onChange={(file) => setFieldValue("favicon_icon", file)}
                      error={errors?.favicon_icon}
                      touched={touched.favicon_icon}
                    />
                    {touched.favicon_icon && errors?.favicon_icon && (
                      <ValidationFeedback title={errors?.favicon_icon} />
                    )}
                  </div>

                  <hr className="my-5" />
                  <Row>
                    <Col md={6} className="">
                        <CommonFormLabel htmlFor="logo" title="Uploaded Image" />
                        <div className="mt-2">
                        <img src={settings.url + "/" + settings.logo} alt="Logo" className="img-fluid" height={"150px"} width={"150px"} />
                      </div>
                    </Col>
                    <Col md={6} className="">
                        <CommonFormLabel htmlFor="favicon_icon" title="Uploaded Favicon" />
                        <div className="mt-2">
                        <img src={settings.url + "/" + settings.favicon_icon} alt="Favicon" className="img-fluid" height={"150px"} width={"150px"} />
                      </div>
                    </Col>
                  </Row>
                  <hr className="my-5" />

                  <div className="d-flex align-items-center justify-content-center gap-3 common-popup-btn">
                    <CommonButton title={isSubmitting ? "Updating..." : "Update"} type="submit" disabled={isSubmitting} className="btn-primary primary-shadow" />
                    <CommonButton title="Cancel" onClick={handleModalClose} className="btn-muted" type="button" />
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
